<template>
  <div class="mobile-header-bar">
    <div class="logo" @click="() => $router.push({name: 'mhome'})">
      <img src="/imgs/logo1.png" alt="" />
    </div>
    <div class="menu-btn" @click="menuSel">
      <div
        :class="['menu-list', menuShow ? 'show-menu' : 'hide-menu']"
        ref="menuList"
      >
        <div class="close">
          <img
            src="../../../../public/imgs/close.png"
            @click="closeMenu"
            alt=""
          />
        </div>
        <ul>
          <li @click="goThere(item.router)" v-for="(item, index) in menuData">
            {{ item.name }}
          </li>
          <li @click="goHirePage()">诚聘英才</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import menuData from "../../headerBar/menu.json";
export default {
  name: "",
  data() {
    return {
      // menuData,
      menuShow: false,
    };
  },
  computed: {
    menuData() {
      return this.$store.state.permission.menu;
    },
  },
  methods: {
    menuSel(e) {
      e.stopPropagation();
      this.menuShow = true;
      this.$refs.menuList.style.display = "block";
      this.$emit("is-scroll", "hidden");
    },
    closeMenu(e) {
      e.stopPropagation();
      this.menuShow = false;
      this.$emit("is-scroll", "auto");
    },
    isShow() {
      if (!this.menuShow) {
        this.$refs.menuList.style.display = "none";
      } else {
        this.$refs.menuList.style.display = "block";
      }
    },
    goThere(router) {
      this.$router.push({
        name: 'm'+router,
      });
    },
    /** 诚聘英才 */
    goHirePage() {
      window.open('/JOINUS.html');
    }
  },
  mounted() {
    this.$refs.menuList.addEventListener("webkitAnimationEnd", this.isShow);
  },
  beforeDestroy() {
    this.$refs.menuList.removeEventListener("webkitAnimationEnd", this.isShow);
  },
};
</script>

<style lang="less" scoped>
.mobile-header-bar {
  width: 94%;
  margin: 0 3%;
  height: 2.222rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    line-height: 2.222rem;
    height: 2.222rem;
    img {
      width: auto;
      height: 65%;
      vertical-align: middle;
    }
  }
  .menu-btn {
    width: 1.333rem;
    height: 1.333rem;
    background: url(../../../../public/imgs/menu-icon.png) center no-repeat;
    background-size: 100% 100%;
    .menu-list {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: 100%;
      background: rgba(36, 95, 92, 0.9);
      z-index: 999;
      overflow: hidden;
      .close {
        padding: 0.5rem 0.5rem 0 0.5rem;
        img {
          width: 1.744rem;
          height: 1.744rem;
        }
      }
      ul {
        padding-top: 1rem;
        li {
          text-align: center;
          line-height: 1rem;
          margin-bottom: 1rem;
          color: #fff;
          font-size: 0.667rem;
        }
      }
    }
    .show-menu {
      animation: show 0.7s;
    }
    .hide-menu {
      animation: hide 0.7s;
    }
    @keyframes show {
      from {
        height: 0%;
      }
      to {
        height: 100%;
      }
    }
    @keyframes hide {
      from {
        height: 100%;
      }
      to {
        height: 0%;
      }
    }
  }
}
</style>
